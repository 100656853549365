import React, { createContext, useState, useEffect, useContext } from 'react';
import commonApi from '../utiles/api';
import { CommonContext } from './CommonContext';
import { useLocation } from 'react-router-dom';

export const BranchContext = createContext();

export function BranchContextProvider({ children }) {
    const { handleLogout } = useContext(CommonContext);
    const location = useLocation();
    const [addModal, setAddModal] = useState(false);
    const [desListData, setDesListData] = useState([]);
    const [branchDropData, setBranchDropData] = useState([]);
    const [desDropData, setDesDropData] = useState([]);
    const [branchListData, setBranchListData] = useState([]);
    const [memberListData, setMemberListData] = useState([]);
    const [announcementListData, setAnnouncementListData] = useState([]);
    const [advisoryListData, setAdvisoryListData] = useState([]);
    const [locationData, setLocationData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [alert, setAlert] = useState({ type: '', message: '', visible: false });
    const [formTemp, setFormTemp] = useState({
        status: '1',
    });
    const handleAddModals = () => {
        setAddModal(true);
        setIsEdit(false);
    };

    const handleCloseModal = () => {
        setAddModal(false);
        setIsEdit(false);
        setFormTemp({
            status: '1',
        });
    };

    const onDesLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/desg/list');
            if (response.data.s === 1) {
                setDesListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onDesDropApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/desg/dropdown');
            if (response.data.s === 1) {
                setDesDropData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onBranchDropApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/dropdown');
            if (response.data.s === 1) {
                setBranchDropData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const handleExport = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/export');
            if (response.data.s === 1) {
                setAlert({ type: 'success', message: response.data.msg, visible: true });
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
                setAlert({ type: 'danger', message: error?.response?.data?.msg || error.message, visible: true });
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onBranchLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/list');
            if (response.data.s === 1) {
                setBranchListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onMemberLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/members/list', { branch_id: location?.state?.id });
            if (response.data.s === 1) {
                setMemberListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onAnnouncementLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/announcement/list');
            if (response.data.s === 1) {
                setAnnouncementListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };
    const onAdvisoryLoadApi = async () => {
        setIsLoading(true);
        try {
            const response = await commonApi.post('branch/advisory/list');
            if (response.data.s === 1) {
                setAdvisoryListData(response.data.data);
            }
        } catch (error) {
            if (error.response && error.response.status === 401) {
                handleLogout();
                console.error('Unauthorized access - Redirecting to login');
            } else {
                console.error('Failed to fetch desListData:', error);
            }
        } finally {
            setIsLoading(false);
        }
    };


    useEffect(() => {
        if (location?.state) {
            setLocationData(location?.state);
        }
    }, [location]);
    useEffect(() => {
        onDesDropApi();
        onBranchDropApi();
        if (location?.state) {
            onMemberLoadApi();
        }
    }, []);

    const contextValue = {
        handleExport,
        setAlert,
        alert,
        onAdvisoryLoadApi,
        advisoryListData,
        setAdvisoryListData,
        onAnnouncementLoadApi,
        announcementListData,
        setAnnouncementListData,
        branchDropData,
        setBranchDropData,
        locationData,
        setLocationData,
        onMemberLoadApi,
        memberListData,
        setMemberListData,
        onDesDropApi,
        desDropData,
        onDesLoadApi,
        onBranchLoadApi,
        branchListData,
        setBranchListData,
        isEdit,
        setIsEdit,
        formTemp,
        setFormTemp,
        addModal,
        setAddModal,
        handleCloseModal,
        handleAddModals,
        desListData,
        setDesListData,
        isSearch,
        isLoading,
        setIsLoading,
        setIsSearch,
        onDesLoadApi
    };

    return <BranchContext.Provider value={contextValue}>{children}</BranchContext.Provider>;
}
