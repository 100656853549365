import React, { useState, useContext } from 'react';
import {
    Navbar,
    NavbarBrand,
    NavbarToggler,
    Nav,
    NavItem,
    Collapse
} from 'reactstrap';
import { useLocation } from 'react-router';

import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import '../../assets/Sidebar.scss';
import '../../assets/theme-bordered.css';
import Logo from '../../logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faChartLine,
    faImage,
    faMapPin,
    faMapLocation,
    faLocation,
    faBox,
    faPager,
    faNewspaper,
    faBlog,
    faLayerGroup,
    faMailBulk,
    faMonument,
    faRotate,
    faHandshake,
    faReply,
    faMagnet,
    faRupee,
    faRoad,
    faHighlighter,
    faManatSign,
    faMapMarked,
    faContactCard,
    faCircle,
    faDotCircle,
    faChevronDown,
    faChevronRight,
    faFile,
    faBell,
    faCodeBranch,
    faCediSign,
    faUsers,
    faUser,
    faInstitution,
    faVolumeUp,
    faComments,
    faTowerBroadcast,
    faChartArea,
    faClapperboard,
    faNoteSticky,
    faCalendarAlt,
    faCreditCard,
    faBookReader,
    faJoint,
    faStepForward,
    faBookAtlas,
    faBook
} from '@fortawesome/free-solid-svg-icons';
import { CommonContext } from '../../context';
import { faArtstation, faMeetup } from '@fortawesome/free-brands-svg-icons';
// import { faSection } from '@fortawesome/free-solid-svg-icons/faSection';

const Sidebar = () => {
    const {
        isHovered,
        setIsHovered,
        isSidebarOpen,
        setIsSidebarOpen,
    } = useContext(CommonContext);

    const [openSection, setOpenSection] = useState(null);
    const urlPath = useLocation();

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);
    const handleMouseEnter = () => setIsHovered(true);
    const handleMouseLeave = () => setIsHovered(false);

    const toggleSection = (section) => {
        setOpenSection(prevState => (prevState === section ? null : section));
    };

    return (
        <div
            className={`bg-menu-theme custom-scrollbar scrollable ${isSidebarOpen || isHovered ? '' : 'collapsed'}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <Navbar color="light" light className="menu-vertical" style={{ flexDirection: 'column' }}>
                <div className='top'>
                    <NavbarBrand href="/" className="app-brand-link">
                        <span className="app-brand-logo demo">
                            <img src={Logo} className='img-fluid' alt="Logo" />
                        </span>
                    </NavbarBrand>
                    <NavbarToggler onClick={toggleSidebar} className="ms-auto">
                        <FontAwesomeIcon
                            icon={isSidebarOpen ? faDotCircle : faCircle}
                            className="menu-toggle-icon"
                        />
                    </NavbarToggler>
                </div>
                <Nav navbar className="menu-inner-shadow flex-column w-100">
                    <NavItem className={`menu-item ${urlPath.pathname === '/' ? 'active' : ''}`}>
                        <Link to="/" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faImage} className="menu-icon" />
                            <div>Banners</div>
                        </Link>
                    </NavItem>

                    <NavItem className={`menu-item sub ${urlPath.pathname === '/master' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('master')}>
                            <FontAwesomeIcon icon={faLayerGroup} className="menu-icon" />
                            <div>Master</div>
                            <FontAwesomeIcon
                                icon={openSection === 'master' ? faChevronDown : faChevronRight}
                                className="menu-toggle-icon ms-auto"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'master'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item sub ${urlPath.pathname === '/category' ? 'active' : ''}`}>
                                    <Link to="/category" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faChartLine} className="menu-icon" />
                                        <div>Category</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item sub ${urlPath.pathname === '/assets-class' ? 'active' : ''}`}>
                                    <Link to="/assets-class" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faNoteSticky} className="menu-icon" />
                                        <div>Assets Class</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item sub ${urlPath.pathname === '/financialYear' ? 'active' : ''}`}>
                                    <Link to="/financialYear" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faCalendarAlt} className="menu-icon" />
                                        <div>Financial Year</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item sub ${urlPath.pathname === '/fee-structure' ? 'active' : ''}`}>
                                    <Link to="/fee-structure" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faCreditCard} className="menu-icon" />
                                        <div>Fee Structure</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/city' ? 'active' : ''}`}>
                                    <Link to="/city" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faMapPin} className="menu-icon" />
                                        <div>City</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/state' ? 'active' : ''}`}>
                                    <Link to="/state" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faMapLocation} className="menu-icon" />
                                        <div>State</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/pincode' ? 'active' : ''}`}>
                                    <Link to="/pincode" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faLocation} className="menu-icon" />
                                        <div>Pincode</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/Country' ? 'active' : ''}`}>
                                    <Link to="/Country" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faMapMarked} className="menu-icon" />
                                        <div>Country</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/event' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('event')}>
                            <FontAwesomeIcon icon={faMonument} className="menu-icon" />
                            <div>Events</div>
                            <FontAwesomeIcon
                                icon={openSection === 'event' ? faChevronDown : faChevronRight}
                                className="menu-toggle-icon ms-auto"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'event'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/eventtransaction' ? 'active' : ''}`} >
                                    <NavItem className={`menu-item ${urlPath.pathname === '/event' ? 'active' : ''}`} >
                                        <Link to="event" className="menu-link menu-toggle">
                                            <FontAwesomeIcon icon={faTowerBroadcast} className="menu-icon" />
                                            <div>Event</div>
                                        </Link>
                                    </NavItem>
                                    <Link to="eventtransaction" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faRotate} className="menu-icon" />
                                        <div>Event Transactions</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/branch' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('branch')}>
                            <FontAwesomeIcon icon={faCodeBranch} className="menu-icon" />
                            <div>Branch</div>
                            <FontAwesomeIcon
                                icon={openSection === 'branch' ? faChevronDown : faChevronRight}
                                className="menu-toggle-icon ms-auto"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'branch'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/branch/designation' ? 'active' : ''}`} >
                                    <Link to="/branch/designation" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faCediSign} className="menu-icon" />
                                        <div>Designation</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/branch' ? 'active' : ''}`} >
                                    <Link to="/branch" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faCodeBranch} className="menu-icon" />
                                        <div>Branch</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/branch/announcements' ? 'active' : ''}`} >
                                    <Link to="branch/announcements" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faVolumeUp} className="menu-icon" />
                                        <div>Announcement</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === 'advisory' ? 'active' : ''}`} >
                                    <Link to="advisory" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faMeetup} className="menu-icon" />
                                        <div>Advisory</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/member' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('members')}>
                            <FontAwesomeIcon icon={faUsers} className="menu-icon" />
                            <div>Members</div>
                            <FontAwesomeIcon
                                icon={openSection === 'members' ? faChevronDown : faChevronRight}
                                className="menu-toggle-icon ms-auto"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'members'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/member-category' ? 'active' : ''}`} >
                                    <Link to="/member-category" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faChartArea} className="menu-icon" />
                                        <div>Category</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/member-class' ? 'active' : ''}`} >
                                    <Link to="/member-class" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faClapperboard} className="menu-icon" />
                                        <div>Class</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/meetings' ? 'active' : ''}`} >
                                    <Link to="/meetings" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faMeetup} className="menu-icon" />
                                        <div>Meeting</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/individual-member' ? 'active' : ''}`} >
                                    <Link to="individual-member" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faUser} className="menu-icon" />
                                        <div>Individual</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/institutional-member' ? 'active' : ''}`} >
                                    <Link to="/institutional-member" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faInstitution} className="menu-icon" />
                                        <div>Institutional</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item sub ${openSection === 'courses' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('courses')}>
                            <FontAwesomeIcon icon={faBook} className="menu-icon" />
                            <div>IOV Courses</div>
                            <FontAwesomeIcon
                                icon={openSection === 'courses' ? faChevronDown : faChevronRight}
                                className="menu-toggle-icon ms-auto"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'courses'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item sub ${urlPath.pathname === '/faq-category' ? 'active' : ''}`}>
                                    <Link to="/faq-category" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faChartLine} className="menu-icon" />
                                        <div>FAQ Category</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/contactus' ? 'active' : ''}`}>
                        <Link to="/contactus" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faContactCard} className="menu-icon" />
                            <div>Contact Us</div>
                        </Link>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/popups' ? 'active' : ''}`}>
                        <Link to="/popups" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faBox} className="menu-icon" />
                            <div>Popups</div>
                        </Link>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/cms' ? 'active' : ''}`}>
                        <Link to="/cms" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faPager} className="menu-icon" />
                            <div>CMS Pages</div>
                        </Link>
                    </NavItem>
                    {/* <NavItem className={`menu-item ${urlPath.pathname === '/cms2' ? 'active' : ''}`}>
                        <Link to="/cms2" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faPager} className="menu-icon" />
                            <div>CMS Pages-2</div>
                        </Link>
                    </NavItem> */}
                    <NavItem className={`menu-item ${urlPath.pathname === '/seo' ? 'active' : ''}`}>
                        <Link to="/seo" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faPager} className="menu-icon" />
                            <div>SEO</div>
                        </Link>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/file-manager' ? 'active' : ''}`}>
                        <Link to="/file-manager" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faFile} className="menu-icon" />
                            <div>File Manager</div>
                        </Link>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/alerts' ? 'active' : ''}`}>
                        <Link to="/alerts" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faBell} className="menu-icon" />
                            <div>Alerts</div>
                        </Link>
                    </NavItem>

                    <NavItem className={`menu-item ${urlPath.pathname === '/e-library' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('e-library')}>
                            <FontAwesomeIcon icon={faBookReader} className="menu-icon" />
                            <div>E Library</div>
                            <FontAwesomeIcon
                                icon={openSection === 'e-library' ? faChevronDown : faChevronRight}
                                className="menu-toggle-icon ms-auto"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'e-library'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/news' ? 'active' : ''}`}>
                                    <Link to="/news" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faNewspaper} className="menu-icon" />
                                        <div>News</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/blogs' ? 'active' : ''}`}>
                                    <Link to="/blogs" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faBlog} className="menu-icon" />
                                        <div>Blogs</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/article' ? 'active' : ''}`}>
                                    <Link to="/article" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faArtstation} className="menu-icon" />
                                        <div>Article</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/books' ? 'active' : ''}`}>
                                    <Link to="/books" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faBookAtlas} className="menu-icon" />
                                        <div>Books</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/e-Journal' ? 'active' : ''}`}>
                                    <Link to="/e-Journal" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faLayerGroup} className="menu-icon" />
                                        <div>E-Journal</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/tenders' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('tender')}>
                            <FontAwesomeIcon icon={faMailBulk} className="menu-icon" />
                            <div>Tender</div>
                            <FontAwesomeIcon
                                icon={openSection === 'tender' ? faChevronDown : faChevronRight}
                                className="menu-toggle-icon ms-auto"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'tender'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/tender' ? 'active' : ''}`}>
                                    <Link to="/tender" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faHandshake} className="menu-icon" />
                                        <div>Tender</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/tenderreply' ? 'active' : ''}`}>
                                    <Link to="/tenderreply" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faReply} className="menu-icon" />
                                        <div>Tender Reply</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/grievance' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('grievance')}>
                            <FontAwesomeIcon icon={faStepForward} className="menu-icon" />
                            <div>Grievance</div>
                            <FontAwesomeIcon
                                icon={openSection === 'grievance' ? faChevronDown : faChevronRight}
                                className="menu-toggle-icon ms-auto"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'grievance'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/grievance-payments' ? 'active' : ''}`}>
                                    <Link to="/grievance-payments" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faRupee} className="menu-icon" />
                                        <div>Grievance Payments</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/grievance-reply' ? 'active' : ''}`}>
                                    <Link to="/grievance-reply" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faReply} className="menu-icon" />
                                        <div>Grievance Reply</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>
                    <NavItem className={`menu-item ${urlPath.pathname === '/testimonials' ? 'active' : ''}`}>
                        <Link to="/testimonials" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faComments} className="menu-icon" />
                            <div>Testimonials</div>
                        </Link>
                    </NavItem>

                    <NavItem className={`menu-item ${urlPath.pathname === '/iov-journey' ? 'active' : ''}`}>
                        <Link to="/iov-journey" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faRoad} className="menu-icon" />
                            <div>IOV Journey</div>
                        </Link>
                    </NavItem>

                    <NavItem className={`menu-item ${urlPath.pathname === '/career' ? 'active' : ''}`}>
                        <Link to="#" className="menu-link menu-toggle" onClick={() => toggleSection('career')}>
                            <FontAwesomeIcon icon={faJoint} className="menu-icon" />
                            <div>career</div>
                            <FontAwesomeIcon
                                icon={openSection === 'career' ? faChevronDown : faChevronRight}
                                className="menu-toggle-icon ms-auto"
                            />
                        </Link>
                        <Collapse isOpen={openSection === 'career'}>
                            <Nav navbar className="flex-column">
                                <NavItem className={`menu-item ${urlPath.pathname === '/career-opportunity' ? 'active' : ''}`}>
                                    <Link to="/career-opportunity" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faHighlighter} className="menu-icon" />
                                        <div>Career Opportunity</div>
                                    </Link>
                                </NavItem>
                                <NavItem className={`menu-item ${urlPath.pathname === '/career-opportunity-reply' ? 'active' : ''}`}>
                                    <Link to="/career-opportunity-reply" className="menu-link menu-toggle">
                                        <FontAwesomeIcon icon={faReply} className="menu-icon" />
                                        <div>Career Opportunity Reply</div>
                                    </Link>
                                </NavItem>
                            </Nav>
                        </Collapse>
                    </NavItem>

                    <NavItem className={`menu-item ${urlPath.pathname === '/manage-users' ? 'active' : ''}`}>
                        <Link to="/manage-users" className="menu-link menu-toggle">
                            <FontAwesomeIcon icon={faManatSign} className="menu-icon" />
                            <div>Manage Users and Permissions</div>
                        </Link>
                    </NavItem>
                </Nav>
            </Navbar>
        </div>
    );
};

export default Sidebar;
